/*
 * 업무구분: 고객
 * 화 면 명: MSPCM003M
 * 화면설명: 마케팅 동의
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.05.31
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container title="마케팅 동의" :show-title="true" type="subpage" @on-header-left-click="fn_ClickPrev" ref="topRef">
    <ur-box-container direction="column" alignV="start" class="ns-agreeArea2">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">
        <div class="agree-box">
          <span class="agree-title">
            소비자 권익보호에 관한 사항
          </span>
          <span class="agree-txt">
            <strong>
              본 동의는 보험계약 체결에 필수적이지 않으며 동의를 거부하실 수 있습니다. 
            </strong>
             &nbsp;또한 동의하시더라도 당사 홈페이지 및 마케팅동의 철회요청 전화[080-331-3114]를 통해 철회하거나 
             가입 권유 목적의 연락에 대한 중단을 요청하실 수 있으며 연락중지청구시스템 (www.donotcall.or.kr)을 
             통해 언제든지 마케팅활동에 대한 중지를 요청할 수 있습니다. 동의하시는 경우 상품이나 서비스의 홍보 및 
             판매권유 등의 목적으로 개인정보를 이용하여 연락을 드릴 수 있습니다. 
          </span>
          <div class="-pub-grade-bar">
            <div class="-pub-grade-bar--item -pub-grade-bar--item__selected">
              <span class="-pub-grade-bar--item__color -pub-grade-bar--item__color__safe"></span> 
              <span class="-pub-text">안심</span>
            </div> 
            <div class="-pub-grade-bar--item">
              <span class="-pub-grade-bar--item__color -pub-grade-bar--item__color__good"></span> 
              <span class="-pub-text">양호</span>
            </div> 
            <div class="-pub-grade-bar--item">
              <span class="-pub-grade-bar--item__color -pub-grade-bar--item__color__normal"></span> 
              <span class="-pub-text">보통</span>
            </div> 
            <div class="-pub-grade-bar--item">
              <span class="-pub-grade-bar--item__color -pub-grade-bar--item__color__caution"></span> 
              <span class="-pub-text">신중</span>
            </div> 
            <div class="-pub-grade-bar--item">
              <span class="-pub-grade-bar--item__color -pub-grade-bar--item__color__warning"></span> 
              <span class="-pub-text">주의</span>
            </div>
          </div>
          <span class="agree-txt">
            ※<b>위 등급</b>은 사생활 침해 위험, 혜택, 명확성 등을 종합적으로 고려하여 동의내용의 평가등급을 제공하는 제도입니다.
          </span>
        </div>
        <div class="chk-box">
          <div class="chk-title">
            상품소개를 위한 상세 동의서
          </div>
          <div class="ns-check">
            <mo-checkbox v-model="isAllAgree" @input="fn_SelectAgreeAll">전체동의</mo-checkbox>
            <mo-checkbox v-model="dontAgreeCheckbox" @input="fn_CancelAgreeAll">동의안함</mo-checkbox>
          </div>
        </div>
        <mo-list-item expandable prevent-expand class="agree-list" ref="listItems" preventScrollWhenExpand>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <div class="ns-check">
                <mo-checkbox v-model="agreeCheckboxList" value="1" @input="fn_ClickedCheck(0)">1. 수집 · 이용에 관한 사항</mo-checkbox>
              </div>
            </div>
          </div>
          <template v-slot:expand>
            <div class="list-txtBox">
              <strong>■ 수집 · 이용 목적</strong>
              <p class="">당사의 금융상품(보험,융자, 수익증권,신탁, 퇴직연금)안내 및 서비스(상속설계, 건강관리서비스, 세미나)안내, 이벤트 안내, 시장조사, 경품 제공</p>
            </div>
            <div class="list-txtBox">
              <strong>■ 보유 · 이용기간</strong>
              <p class="txt-underline">고객님께서 선택하신 개인(신용)정보의 보유·이용기간</p>
            </div>
            <div class="list-txtBox">
              <strong>■ 거부 권리 및 불이익</strong>
              <p>
                귀하는 아래 개인(신용) 정보 수집·이용에 관한 동의를 거부하실 수 있습니다. 다만, 동의하지 않으시는 경우 
                "당사의 금융상품(보험, 융자, 수익증권, 신탁, 퇴직연금)안내 및 서비스(상속설계, 건강관리서비스, 세미나)안내, 이벤트 안내, 시장조사, 경품 제공"의 혜택을 받지 못할 수 있습니다.
              </p>
            </div>
            <div class="list-txtBox last">
              <strong>■ 수집·이용 항목</strong>
              <p>-개인(신용)정보</p>
              <p class="pt15">
                &middot; 일반개인정보<br>
                성명, 생년월일, 성별, 주소, 전화번호, 이메일, 직업, 직장, 가족관계정보
              </p>
              <p class="pt15">
                &middot; 신용거래정보<br>
                보험계약정보(상품종류, 기간, 보험가입금액)
              </p>
              <p class="pt15">
                &middot; 신용능력정보<br>
                자산규모, 금융자산규모
              </p>
              <div class="ns-check">
                <p>위 개인신용정보 <span class="txt-underline">수집·이용</span>에 동의하십니까?</p>
                <mo-checkbox v-model="agreeSubCheckboxList[0]" @input="fn_selectedSubCb(1, 0, 'agree')">동의함</mo-checkbox>
                <mo-checkbox v-model="dontAgreeSubCheckboxList[0]" @input="fn_selectedSubCb(1, 0, 'dontAgree')">동의안함</mo-checkbox>
                <span class="txt-point">
                  ※ 본 동의는 당사와 모집위탁계약을 체결한 업무수탁자(당사 전속 보험설계사 및 동 계약을 
                  모집한 보험대리점)가 수집·이용하는 것을 포합합니다.<br>
                  ※ 상기 내용에 동의하시는 경우 당사 임직원 또는 업무수탁자가 상품서비스 소개 및 판매 안내연락(TM 등)을 드릴 수 있습니다.
                </span>
              </div>
            </div>
          </template>
        </mo-list-item>
        <div class="chk-box2">
          <div class="ns-check">
            <mo-checkbox v-model="agreeCheckboxList" value="2" @input="fn_ClickedCheck(1)">
              2. 광고성 정보의 수신동의<br>
              전자적 전송매체를 통한 광고성 정보의 수신을 동의하시겠습니까?
            </mo-checkbox>
          </div>
        </div>
        <div class="chk-box3">
          <div class="ns-check">
            <mo-checkbox v-if="false" v-model="allMarketingCheckbox" @input="fn_ClickedAllMkt">전체</mo-checkbox> <!-- 전체는 안보이도록 변경 -현업요청 (2022.07.28)-->
            <mo-checkbox v-model="marketingCheckboxList" value="S">문자</mo-checkbox>
            <mo-checkbox v-model="marketingCheckboxList" value="E">이메일</mo-checkbox>
            <br>
            <mo-checkbox v-model="marketingCheckboxList" value="M">DM</mo-checkbox>
            <mo-checkbox v-model="marketingCheckboxList" value="P">전화</mo-checkbox>
          </div>
        </div>
        
      </ur-box-container>
      <div class="agree-box-desc"><span>CISO 심의필[2023-080]</span></div>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column">
        <div class="agree-box2">
          <span class="agree-title">
            당사와 거래여부 체크
          </span>
          <div class="ns-segment ns0000001">
            <mo-segment-wrapper solid primary v-model="segmentType1">
              <mo-segment-button value="1">거래</mo-segment-button>
              <mo-segment-button value="2">미거래</mo-segment-button>
            </mo-segment-wrapper>
          </div>
          <span class="agree-title" v-if="segmentType1 !== undefined">
            개인(신용)정보 보유이용기간
          </span>
          <div class="ns-segment ns0000002" v-if="segmentType1 !== undefined">
            <mo-segment-wrapper solid primary v-model="segmentType2">
                <mo-segment-button v-for="(item, index) in segmentTypes2" :key="index" :value="item.key" @click="fn_ClickSegmentType2">{{item.label}}</mo-segment-button>
            </mo-segment-wrapper>
          </div>
        </div>
        
        <div class="ns-height100"></div>
        <!-- 동의 안내 bottomSheet -->
        <mo-bottom-sheet ref="CnsntCnfrmPop" class="ns-bottom-sheet ns-style3">
          <div>
          <p class="cancel-ment left">
            반드시 고객이 신청한 동의기간을 입력바랍니다.(임의 입력 절대불가)<br>임의 입력 적발시 관련 법령에 의해 처벌을 받을 수도 있습니다.<br>※사전 동의 없이 개인정보를 수집하는 행위(유효기간 /서명 임의 기재  등) 적발시 개인정보보호법 및 신용정보법에 의해 최대 10년 이하 징역 또는 1억원 이하 벌금에 처할 수 있음
          </p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CnsntCnfrmPop('Confirm')">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickPrev">이전</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!nextBtnDisabled" @click="fn_ClickNext">다음</mo-button>
        </div>
      </ur-box-container>

      <!-- 
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-top-scroll-btn">
          <mo-button class="ns-top-btn">top</mo-button>
        </ur-box-container>
      -->

    </ur-box-container>
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import Msg from '@/systems/webkit/msg/msg'
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPCM003M",
    screenId: "MSPCM003M",
    components: {},
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    
    /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
    created() {
      this.pCustNm = !this.$bizUtil.isEmpty(this.$route.params.custNm) ? this.$route.params.custNm : ''
      if(!_.isEmpty(this.$route.params)){
        this.agreeTypeMcnst = this.$route.params.agreeTypeMcnst //필컨 정보
        this.mktChkList = this.$route.params.mktChkList //마컨 정보(광고성 수신동의)
        this.segmentType1 = this.$route.params.segmentType1 // 거래(value:1), 미거래(value:2)
        this.segmentType2 = this.$route.params.mktCnsntAvlPrd //마컨 정보(개인정보 보유이용기간)
        this.mktCnsntAvlPrd = this.$route.params.mktCnsntAvlPrd //마컨 정보(개인정보 보유이용기간)
        this.infoPrcusCnsntTypCd =  this.$route.params.infoPrcusCnsntTypCd //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18

        // 리턴 된 파라미터 가 있을 경우, 전체동의 체크, 1.수집이용에관한 사항, 2광고성 정보의 수신동의 체크
        if(!_.isEmpty(this.mktChkList)){
          this.agreeCheckboxList = ['1', '2']
          this.marketingCheckboxList = this.mktChkList
          this.allMarketingCheckbox = true
          this.agreeSubCheckboxList = [true]
          this.dontAgreeSubCheckboxList = [false]
          this.segmentType2 = this.$route.params.mktCnsntAvlPrd
        }
      } else {
        // 고객 정보
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo

        this.infoPrcusCnsntTypCd = storeCustInfo.infoPrcusCnsntTypCd
        // 필동
        this.agreeTypeMcnst = storeCustInfo.agreeTypeMcnst

        // 마동
        if (!_.isEmpty(storeCustInfo.mktChkList)) {
          this.marketingCheckboxList = storeCustInfo.mktChkList
          this.segmentType1 = storeCustInfo.segmentType1
          this.segmentType2 = storeCustInfo.mktCnsntAvlPrd
          this.mktCnsntAvlPrd = storeCustInfo.mktCnsntAvlPrd

          this.pCustNm = storeCustInfo.custNm
        }
      }

      this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
        this.$refs['listItems'].expand() // 아코디언 펼침 처리
      })
      // 스크롤 컴포넌트ID
      this.scrollCompID = 'scroll_' + this.$options.screenId

      // backButton event 등록
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPCM003M')
      this.pageScrollAnimateY = createScrollAnimateInstance(document.getElementById(this.scrollCompID), 'y')
    },
    beforeDestroy () {
      // backButton event 해제
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickPrev)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        scrollCompID: '', // 스크롤영역 ID
        scrollAnimateY: null,
        title: '마케팅 동의', // 화면명
        pCustNm: '', // 파라미터로 받은 고객명
        scrollAnimateY: null,
        agreeTypeMcnst: false, //필컨 정보 여부
        mktChkList:{}, //마케팅 권유방식 체크 항목
        mktCnsntAvlPrd:'', // 마케팅 보유기간
        infoPrcusCnsntTypCd: '', // 정보활용동의 유형코드(17: 필수컨설팅, 19: 마케팅동의, 18: 둘다)
        nextBtnDisabled: false,  //다음버튼 활성화
        dontAgreeCheckbox: false,
        isAllAgree: false,
        agreeSubCheckboxList: [false],
        dontAgreeSubCheckboxList: [false],
        allMarketingCheckbox : false, //2.광고성 전체 선택
        agreeCheckboxList: [],
        marketingCheckboxList: [],
        segmentType1: '', //당사와 거래여부 체크(거래, 미거래) 선택
        segmentType2: '', //개인(신용)정보 보유이용기간 선택
        segmentTypes2: [], //segmentTwoYears or segmentFiveYears 담기
        segmentTwoYears: [
        {
          key: '2',
          label: '2년'
        },
        {
          key: '1',
          label: '1년'
        }
      ],
      segmentFiveYears: [
        {
          key: '5',
          label: '5년'
        },
        {
          key: '4',
          label: '4년'
        },
        {
          key: '3',
          label: '3년'
        },
        {
          key: '2',
          label: '2년'
        },
        {
          key: '1',
          label: '1년'
        }
      ]
      };

    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
      completed () {
        let lv_AllAgree = false
        if (!this.isAllAgree) {
          let lv_FindVal = this.agreeCheckboxList.filter(
            value => {
              if (value === '1' || value === '2') {
                return value
              }
            }
          )
          if (lv_FindVal !== undefined && lv_FindVal.length === 2 && this.marketingCheckboxList.length > 0) {
            lv_AllAgree = true
          }
        }
        return (this.isAllAgree || lv_AllAgree) && this.segmentType1.length > 0 && this.segmentType2.length > 0
      }
    },
    watch: {
      isAllAgree (newValue) {
        if (newValue) {
          this.dontAgreeCheckbox = false
          this.$nextTick( () => { // DOM의 상태가 변경 될 때 호출
            this.$refs['listItems'].collapse() // 아코디언 접기 처리
          })

          let t_TargetElement = this.$refs.topRef.$el.clientHeight
          this.fn_ScrollTo(t_TargetElement)
        }
      },
      segmentType1 (newValue) {
        if (newValue === '1') { //'거래'
          this.segmentTypes2 = this.segmentFiveYears
        } else if (newValue === '2') { //'미거래'
          this.segmentTypes2 = this.segmentTwoYears
        }
        this.segmentType2 = '' // 초기화
      },
      segmentType2 (newValue){
        this.segmentType2 = newValue
        if(this.isAllAgree === true && this.segmentType2.length > 0){
          this.nextBtnDisabled = true
        }else{
          this.nextBtnDisabled = false
        }
      },
      agreeCheckboxList (newValue) {
        if (newValue.length === 2) {
          this.isAllAgree = true
          if(this.segmentType2.length > 0){ 
            this.nextBtnDisabled = true
          }
        } else {
          this.isAllAgree = false
           this.nextBtnDisabled = false
        }
      },
      marketingCheckboxList (newValue) {
        if (newValue.length > 0) {
          if (this.agreeCheckboxList.indexOf('2') < 0) {
            this.agreeCheckboxList.push('2')
          }
        } else {
          let lv_idx = this.agreeCheckboxList.indexOf('2')
          if (lv_idx > -1) {
            this.agreeCheckboxList.splice(lv_idx, 1)
          }
        }

        if (this.agreeCheckboxList.length === 2) {
          this.isAllAgree = true
        }
        //광고성 정보의 수신동의 전체 선택 후 or 한개라도 해제 시
        if(this.marketingCheckboxList.length > 3){
          this.allMarketingCheckbox = true
        }else{
          this.allMarketingCheckbox = false
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      fn_ScrollTo (scrollTop) {
        this.pageScrollAnimateY.animate(scrollTop, 700, 0, 'easeOut')
      },
      /******************************************************************************
      * Function명 : fn_ClickNext
      * 설명       : 다음 버튼 클릭 시 호출 - (고객인증목록 화면으로 이동)
      ******************************************************************************/
      fn_ClickNext () {
          this.fn_MoveMspcm004mPage()
      },
      /******************************************************************************
      * Function명 : fn_ClickPrev
      * 설명       : 이전 버튼 클릭 시 호출 - (고객동의목록 또는 필컨 화면 이동)
      ******************************************************************************/
      fn_ClickPrev () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)

        // 마동 정보 초기화
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
        storeCustInfo.mktChkList = ''
        storeCustInfo.segmentType1 = ''
        storeCustInfo.mktCnsntAvlPrd = ''
        window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)

        this.$router.go(-1)
        // if(this.infoPrcusCnsntTypCd === '18'){
        //   this.fn_MoveMspcm002mPage()
        // }else{
        //   this.fn_MoveMspcm001mPage()
        // }
      },
      /******************************************************************************
      * Function명 : fn_MoveMspcm001mPage
      * 설명       : MSPCM001M화면으로 이동 (고객동의 목록)
      ******************************************************************************/
      fn_MoveMspcm001mPage () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)

        // 마동 정보 초기화
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
        storeCustInfo.mktChkList = ''
        storeCustInfo.segmentType1 = ''
        storeCustInfo.mktCnsntAvlPrd = ''
        window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)


        this.$router.go(-1)
        // 현재 화면을 history 배열에 저장
        // this.$commonUtil.setLastScreen(this.$options.screenId)
        
        // this.$router.push(
        //   {
        //     name: 'MSPCM001M',
        //     params: {
        //       name: 'MSPCM003M',
        //       infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
        //       custNm: this.pCustNm
        //     }
        //   }
        // )
      },
      /******************************************************************************
      * Function명 : fn_MoveMspcm002mPage
      * 설명       : MSPCM002M화면으로 이동 (필수컨설팅 동의)
      ******************************************************************************/
      fn_MoveMspcm002mPage () {
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt-1)

        // 마동 정보 초기화
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
        storeCustInfo.mktChkList = ''
        storeCustInfo.segmentType1 = ''
        storeCustInfo.mktCnsntAvlPrd = ''

        window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)

        this.$router.go(-1)
        // 현재 화면을 history 배열에 저장
        // this.$commonUtil.setLastScreen(this.$options.screenId)
        
        // this.$router.push(
        //   {
        //     name: 'MSPCM002M',
        //     params: {
        //       name: 'MSPCM003M',
        //       agreeTypeMcnst: this.agreeTypeMcnst, //필컨 정보
        //       infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨, 마동 둘다 : 18
        //       custNm: this.pCustNm,
        //       agreeSubCheckboxList1: [true, true, true],
        //       agreeSubCheckboxList2: [true, true, true],
        //       agreeSubCheckboxList3: [true, true, true],
        //       agreeSubCheckboxList4: [true]

        //     }
        //   }
        // )
      },
      /******************************************************************************
      * Function명 : fn_MoveMspcm004mPage
      * 설명       : MSPCM004M화면으로 이동 (고객인증목록)
      ******************************************************************************/
      fn_MoveMspcm004mPage () {
        // Store 페이지 카운트
        let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
        window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

        let pParam = {
              name: 'MSPCM003M',
              agreeTypeMcnst: this.agreeTypeMcnst, //필컨 정보
              mktChkList: this.marketingCheckboxList, //마컨 정보(광고성 수신동의)
              segmentType1: this.segmentType1, //거래, 미거래
              mktCnsntAvlPrd: this.segmentType2, //마컨 정보(개인정보 보유이용기간)
              infoPrcusCnsntTypCd: this.infoPrcusCnsntTypCd, //필컨만 : 17, 마동만 : 19, 필컨&마동 : 18
              custNm: this.pCustNm
            }

        // 마컨 정보 저장
        let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
        storeCustInfo = Object.assign(storeCustInfo, pParam)
        window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)

        // 현재 화면을 history 배열에 저장
        this.$commonUtil.setLastScreen(this.$options.screenId)
        this.$router.push(
          {
            name: 'MSPCM004M',
            params: pParam
          }
        )
      },
      /******************************************************************************
      * Function명 : fn_SelectAgreeAll
      * 설명       : 전체동의 선택 시
      ******************************************************************************/
      fn_SelectAgreeAll () {
        this.dontAgreeCheckbox = this.dontAgreeCheckbox ? !this.dontAgreeCheckbox : this.dontAgreeCheckbox
        this.agreeCheckboxList = this.isAllAgree ? ['1', '2'] : []
        this.marketingCheckboxList = this.isAllAgree ? ['P', 'S', 'M', 'E'] : []
        if (this.isAllAgree) {
          this.allMarketingCheckbox = true
          this.agreeSubCheckboxList = [true]
          this.dontAgreeSubCheckboxList = [false]
        } else {
          this.allMarketingCheckbox = false
          this.agreeSubCheckboxList = [false]
        }

      },
      /******************************************************************************
      * Function명 : fn_CancelAgreeAll
      * 설명       : 동의안함 선택 시
      ******************************************************************************/
      fn_CancelAgreeAll () {
        if (this.dontAgreeCheckbox) {
          this.agreeCheckboxList = []
          this.agreeSubCheckboxList = [false]
          this.dontAgreeSubCheckboxList = [true]
          this.allMarketingCheckbox = false
          this.marketingCheckboxList = []
        } else {
          this.dontAgreeSubCheckboxList = [false]
        }

        //다음 버튼 비활성화
        this.nextBtnDisabled = false
      },
      /******************************************************************************
      * Function명 : fn_ClickSegmentType2
      * 설명       : 개인(신용)정보 보유 이용기간 선택 시 Alert창 띄움
      ******************************************************************************/
      fn_ClickSegmentType2 () {
          // this.getStore('confirm').dispatch('ADD', this.$t('cm')['ECMC018']) // '반드시 고객이 신청한 동의기간을 입력바랍니다.(임의 입력 절대불가)\n임의 입력 적발시 관련 법령에 의해 처벌을 받을 수도 있습니다.\n\n※사전 동의 없이 개인정보를 수집하는 행위(유효기간 /서명 임의 기재  등) 적발시 개인정보보호법 및 신용정보법에 의해 최대 10년 이하 징역 또는 1억원 이하 벌금에 처할 수 있음'
          this.fn_CnsntCnfrmPop('Open')
      },
      /******************************************************************************
      * Function명 : fn_ClickedAllMkt
      * 설명       : 마케팅 전체 선택/해제 컨트롤 함수
      ******************************************************************************/
      fn_ClickedAllMkt () {
        if(this.allMarketingCheckbox){
          this.marketingCheckboxList = ['P', 'S', 'M', 'E']
        }else{
          this.marketingCheckboxList = []
        }
      },
      /******************************************************************************
      * Function명 : fn_ClickedCheck
      * 설명       : 제목별 전체 선택/해제 컨트롤 함수
      ******************************************************************************/
      fn_ClickedCheck (v) {
        if (this.agreeCheckboxList.includes((v + 1).toString())) {
          if (v === 0) {
            this.agreeSubCheckboxList = [true]
            this.dontAgreeSubCheckboxList = [false]
          }
        } else {
          if (v === 0) {
            this.agreeSubCheckboxList = [false]
          }
        }

        if (v === 1) {
          this.fn_SelectMktAll()
        }
        this.fn_nextBtnDisabled ()
      },
      /******************************************************************************
      * Function명 : fn_SelectMktAll
      * 설명       : 마케팅 권유 방식 선택 시
      ******************************************************************************/
      fn_SelectMktAll () {
        if (this.agreeCheckboxList.indexOf('2') > -1) { // 동의함 선택
          this.allMarketingCheckbox = true
          this.marketingCheckboxList = ['P', 'S', 'M', 'E']
        } else { // uncheck 시
          this.allMarketingCheckbox = false
          this.marketingCheckboxList = []
        }
      },
      /******************************************************************************
      * Function명 : fn_selectedSubCb
      * 설명       : 제목별 선택 시 하위 전체 선택/해제
      ******************************************************************************/
      fn_selectedSubCb (value, idx, flag) {
        if (value === 1) {
          if (flag === 'agree') {
            this.agreeSubCheckboxList[idx] = true
            this.dontAgreeSubCheckboxList[idx] = false
          } else {
            this.agreeSubCheckboxList[idx] = false
            this.dontAgreeSubCheckboxList[idx] = true
          }

          if (this.agreeSubCheckboxList.indexOf(false) < 0) { // 하위 항목 모두 클릭 시
            if (this.agreeCheckboxList.indexOf('1') < 0) {
              this.agreeCheckboxList.push('1')
            }
          } else {
            let lv_idx = this.agreeCheckboxList.indexOf('1')
            if (lv_idx > -1) {
              this.agreeCheckboxList.splice(lv_idx, 1)
            }
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_nextBtnDisabled
      * 설명       : 다음 버튼 활성화/비활성화
      ******************************************************************************/
      fn_nextBtnDisabled () {
        if(this.isAllAgree && this.segmentType2){
          this.nextBtnDisabled = true
        }else{
          this.nextBtnDisabled = false
        }
      },
      /******************************************************************************
      * Function명 : fn_CnsntCnfrmPop
      * 설명       : 동의 안내 
      *           - OPEN(컨펌팝업열기), Confirm(창닫기) 선택에 대한 화면 이동
      ******************************************************************************/
      fn_CnsntCnfrmPop (param) {

        switch(param){
          case 'Open':
            this.$refs.CnsntCnfrmPop.open()
            break
          case 'Confirm':
            this.$refs.CnsntCnfrmPop.close()
            break
        }
        
      },
      /******************************************************************************
      * Function명: _Back
      * 설명: 헤더 영역 Back Button 파라미터 넘기기
      * Params: N/A
      * Return: N/A
      ******************************************************************************/
      _Back () {
        return
        let alertMsg = '고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.'
        let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
        let preSrnId = !this.$bizUtil.isEmpty(preSrnObj.srnId) ? preSrnObj.srnId : 'MSPBC002M'

        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            content: alertMsg,
            title_pos_btn: '나가기'
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(alertObj)
              
              window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화
              this.$router.push({ name: preSrnId, params: preSrnObj.rtnParam })
            },
            onPopupClose: () => {
              this.$bottomModal.close(alertObj)
            }
          }
        })

      },
    }//methods end
  };
</script>
<style scoped>
</style>